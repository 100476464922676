<template lang="pug">
.vc-shared-promoter-campaign-common-m-agreement-view(v-if="data.isDataInited")
  m-agreement-view(:promoter-agreement="promoterAgreement")
</template>

<script setup>
import { computed, onMounted, reactive } from 'vue'
import { useStore, useSwiper } from 'skid-composables'
import mAgreementView from '@sharedComponents/promoter_agreement/common/m-agreement-view.vue'
import kEditorView from '@sharedComponents/common/k-editor-view.vue'

const store = useStore()
const props = defineProps({
  promoterCampaign: { type: Object, required: true }
})

const data = reactive({
  isDataInited: false
})

const promoterAgreement = computed(() => {
  return store.getters['promoterAgreements/find'](
    props.promoterCampaign.agreement_id
  )
})

async function getPromoterAgreement() {
  if (!props.promoterCampaign.agreement_id) return

  await store.dispatch(
    'promoterAgreements/find',
    props.promoterCampaign.agreement_id
  )
}

onMounted(async () => {
  await getPromoterAgreement()

  data.isDataInited = true
})
</script>
